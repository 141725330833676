import React from "react";
// JSX
import HeroSlider, { Slide, Nav, OverlayContainer } from "hero-slider";
import Wrapper from "../Wrapper/Wrapper";
// import Title from "../Title/Title";
// import Subtitle from "../Subtitle/Subtitle";
// // CSS
// import classes from './Slider.module.css'
// import Logo from "./../../assets/jackk-logo.png";

// Images

import image1 from './images/hero-art-deco-cabinet.jpg';
import image2 from './images/hero-kamerscherm.jpg';
import image7 from './images/hero-wandkast.jpg';
import image9 from '../Gallery/images/bauhaus-home-office/jackk-meubels-utrecht-zeist-overzicht-moderne-bauhaus-kast-in-het-bos-no-logo.jpg';
import image10 from '../Gallery/images/coffee-corner/jackk-meubels-utrecht-zeist-jack-is-bezig.jpg';
import image11 from "../Gallery/images/mid-century/jackk-meubels-utrecht-zeist-design-mid-century-inbouw-kast-woonkamer-klep.jpg";
import image12 from "../Gallery/images/modern-tv-meubel/jackk-meubels-utrecht-modern-tv-meubel-verlichting.jpg";
import image13 from "../Gallery/images/origami-table-bench/jackk-meubels-eettafel-eetbank-essen-kersen-schuin.jpg";

const image1alt = "Design Art Deco Kast | Jackk Meubels";
const image2alt = "Design Akoestisch Kamerscherm | Jackk Meubels";
const image7alt = "Design wandkast | Jackk Meubels";
const image9alt = "Design studeerkamer kast | Jackk Meubels";
const image10alt = "Design koffie hoek | Jackk Meubels";
const image11alt = "Design mid-century woonkamer inbouwkast klep detail | Jackk Meubels";
const image12alt = "Design modern tv meubel met verlichting | Jackk Meubels";
const image13alt = "Design eettafel en eetbank Origami, gemaakt van massief essen en kersen | Jackk Meubels Utrecht Zeist";

const app = () => {
  // const nextSlideHandler = React.useRef();
  // const previousSlideHandler = React.useRef();

  return (
    <HeroSlider
      // TODO: add custom next slide previous slide buttons as regular ones don't seem to work properly
      // nextSlide={nextSlideHandler}
      // previousSlide={previousSlideHandler}
      slidingAnimation="left_to_right"
      orientation="horizontal"
      initialSlide={1}
      // onBeforeChange={(previousSlide, nextSlide) =>
      //   console.log("onBeforeChange", previousSlide, nextSlide)
      // }
      // onChange={nextSlide => console.log("onChange", nextSlide)}
      // onAfterChange={nextSlide => console.log("onAfterChange", nextSlide)}
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.33)"
      }}
      settings={{
        slidingDuration: 250,
        slidingDelay: 100,
        shouldAutoplay: true,
        shouldDisplayButtons: true,
        autoplayDuration: 5000,
        height: "100vh"
      }}
    >
      <OverlayContainer>
        <Wrapper>
          {/* <Title><img className={classes.Logo} src={Logo} /></Title>
          <Subtitle>Meubels op maat</Subtitle> */}
          {/* <div>
            <Button
              style={{
                width: 100,
                margin: "12px 8px"
              }}
              button="danger"
              onClick={() => previousSlideHandler.current()}
            >
              Previous
            </Button>
            <Button
              style={{
                width: 100,
                margin: "12px 8px"
              }}
              button="success"
              onClick={() => nextSlideHandler.current()}
            >
              Next
            </Button>
          </div> */}
        </Wrapper>
      </OverlayContainer>

          <Slide
              background={{
                  backgroundImage: image13,
                  backgroundAttachment: "fixed",
                  alt: image13alt
              }}
          />
          <Slide
              background={{
                  backgroundImage: image9,
                  backgroundAttachment: "fixed",
                  alt: image9alt
              }}
          />
          <Slide
              background={{
                  backgroundImage: image12,
                  backgroundAttachment: "fixed",
                  alt: image12alt
              }}
          />
          <Slide
              background={{
                  backgroundImage: image11,
                  backgroundAttachment: "fixed",
                  alt: image11alt
              }}
          />

      <Slide
        background={{
          backgroundImage: image7,
          backgroundAttachment: "fixed",
          alt: image7alt
        }}
      />

  

      <Slide
        background={{
          backgroundImage: image1,
          backgroundAttachment: "fixed",
          alt: image1alt
        }}
      />

          <Slide
              background={{
                  backgroundImage: image10,
                  backgroundAttachment: "fixed",
                  alt: image10alt
              }}
          />

      <Slide
        background={{
          backgroundImage: image2,
          backgroundAttachment: "fixed",
          alt: image2alt
        }}
      />



      <Nav />
    </HeroSlider>
  );
};

export default app;
